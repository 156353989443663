const titles = document.querySelectorAll('.support-item .title');

titles.forEach(title => {
  title.addEventListener('click', () => {
    const faqItem = title.parentElement;
    const isActive = faqItem.classList.contains('active');

    document.querySelectorAll('.support-item.active').forEach(item => {
      item.classList.remove('active');
      item.querySelector('.content').style.height = '0';
    });

    if (!isActive) {
      faqItem.classList.add('active');
      const content = faqItem.querySelector('.content');
      content.style.height = `${content.scrollHeight}px`;
    }
  });
});