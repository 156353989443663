import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";
const Cookies = require('js-cookie')

// Connects to data-controller="crm"
export default class extends Controller {
  static targets = [
    'name',
    'phone',
    'brand',
    'model',
    'requestPath',
    'sourceUrl',
    'specification',
    'budget'
  ];

  connect() {
    let {
      utm_source = "",
      utm_medium = "",
      utm_campaign = "",
      utm_content = "",
      utm_term = ""
    } = Cookies.get();

    this.utm_source = utm_source;
    this.utm_medium = utm_medium;
    this.utm_campaign = utm_campaign;
    this.utm_content = utm_content;
    this.utm_term = utm_term;
  }

  submit() {
    let formData = '&name=' + this.nameTarget.value +
                   '&phone=' + this.phoneTarget.value +
                   '&source_url=' + this.sourceUrlTarget.value +
                   '&brand=' + this.brandTarget.value +
                   '&model=' + this.modelTarget.value +
                   '&specification=' + this.specificationTarget.value +
                   '&budget=' + this.budgetTarget.value +
                   '&utm_source=' + this.utm_source +
                   '&utm_medium=' + this.utm_medium +
                   '&utm_campaign=' + this.utm_campaign +
                   '&utm_content=' + this.utm_content +
                   '&utm_term=' + this.utm_term;

    Rails.ajax({
      type: "post",
      url: this.requestPathTarget.value,
      accept: 'text/javascript',
      data: formData,
      success: (data) => { console.log('Success:', data); },
      error: (error) => { console.error('Error:', error); }
    });
  }
}
