import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['brand']

  updateVehicleBrands(event) {
    const brandId = this.brandTarget.value;
    this.fetchAndUpdate(`/update-vehicle-brands?brand=${brandId}`);
  }

  fetchAndUpdate(url) {
    Rails.ajax({
      type: "get",
      url: url,
      accept: 'text/vnd.turbo-stream.html',
      success: (html) => { Turbo.renderStreamMessage(html) },
      error: (error) => { console.error('Error:', error); }
    });
  }
}
