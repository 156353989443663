import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';

window.addEventListener('turbo:load', () => {

  var swiperPartners = new Swiper('.partners-slider', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    speed: 3500,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
  });

  var myFeedbacksSwiper = new Swiper('.feedbacks-slider ', {
    modules: [Pagination, Navigation, Autoplay],
    loop: true,
    speed: 800,
    slidesPerView: "1",
    spaceBetween: 32,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
    },
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
      pauseOnMouseEnter: true
    }
  });
});
