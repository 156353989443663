import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(PixiPlugin, MotionPathPlugin, ScrollTrigger);

document.addEventListener('turbo:load', function () {
	const animateElement = (element, trigger) => {
		gsap.to(element, {
			color: "#404040",
			scale: 1.3,
			duration: 0.6,
			scrollTrigger: {
				trigger: trigger,
				start: "center 80%",
				toggleActions: "play none none none"
			},
			onComplete: () => {
				gsap.to(element, { duration: 0.6, scale: 1 });
			}
		});
	};

	const animateText = (trigger, title, bodyText) => {
		gsap.to(title, {
			color: "#404040",
			duration: 0.6,
			scrollTrigger: {
				trigger: trigger,
				start: "center 80%",
				toggleActions: "play none none none"
			}
		});

		gsap.fromTo(bodyText, { opacity: 0 }, {
			opacity: 1,
			duration: 0.6,
			scrollTrigger: {
				trigger: trigger,
				start: "center 80%",
				toggleActions: "play none none none"
			}
		});
	};

	document.querySelectorAll(".number").forEach((num) => {
		const parent = num.closest(".stages-item");
		animateElement(num, num);
		animateText(num, parent.querySelector(".stages-title"), parent.querySelector(".body-text-regular"));
	});

	document.querySelectorAll(".line").forEach((line) => {
		const parent = line.closest(".stages-item");
		gsap.fromTo(line, { width: "0" }, {
			width: "100%",
			duration: 0.3,
			scrollTrigger: {
				trigger: line,
				start: "center 80%",
				toggleActions: "play none none none"
			}
		});
		animateText(line, parent.querySelector(".stages-title"), parent.querySelector(".body-text-regular"));
	});
});
