import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
Rails.start()

const Cookies = require('js-cookie')

export default class extends Controller {
  static targets = ["form", "phone"]

  validatePhone(event) {
    const input = event.target;
    input.value = input.value.replace(/[^0-9\+]/g, '').slice(0, 13);
  }

  submit(event) {
    event.preventDefault();
    let formData = new FormData(this.formTarget);

    let { utm_source = "", utm_medium = "", utm_campaign = "", utm_content = "", utm_term = "" } = Cookies.get();

    grecaptcha.ready(() => {
      grecaptcha.execute('6LevtDQqAAAAAGobuNBwQTql75ZDXXCruzKSFKud', { action: 'submit' }).then((token) => {
        formData.append('token', token);
        formData.append('utm_source', utm_source);
        formData.append('utm_medium', utm_medium);
        formData.append('utm_campaign', utm_campaign);
        formData.append('utm_content', utm_content);
        formData.append('utm_term', utm_term);
        formData.append('source_url', window.location.href);

        this.sendRequest(formData);
      });
    });
  }

  sendRequest(formData) {
    Rails.ajax({
      type: 'POST',
      url: 'https://crm.bibicars.bg/new_request_form',
      // url: 'http://localhost:3001/new_request_form',
      data: formData,
      processData: false,
      contentType: false,
      success: (response) => this.handleSuccess(response),
      error: (xhr, status, error) => this.handleError(xhr, status, error)
    });
  }

  handleSuccess(response) {
    window.location.href = '/thank-you';
    if (!sessionStorage.getItem('lead_session_request_sent')) {
      fbq('track', 'Lead');
      sessionStorage.setItem('lead_session_request_sent', true);
    }
  }

  handleError(xhr, status, error) {
    console.error('Form submission failed:', error);
    let successMessage = document.querySelector('.send-form-massage-faile');
    successMessage.style.left = "20px";
    successMessage.innerText = 'Form submission failed';
    setTimeout(() => {
      successMessage.style.left = "-500px";
    }, 5000);
  }
}
